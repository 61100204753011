var bindAction = 'click';
if ('ontouchstart' in document.documentElement) {
	bindAction = 'touchstart';
}

function App() {}

App.showStoreButtons = function() {
	var buttonShown = false;
	
	if(detection.is.pc) {
		$('[data-store-button]').removeClass('hide');
		buttonShown = true;
	}
	else if(detection.is.android) {
		$('[data-store-button="playstore"]').removeClass('hide');	
		buttonShown = true;
	}
	else if(detection.is.ios) {
		$('[data-store-button="appstore"]').removeClass('hide');	
		buttonShown = true;
	}

	if(buttonShown === true) {
		$('.or, .storeButtons').removeClass('invisible hide');
	}
}

App.lazyload = function() {
	$('img[data-original]').each(function() {
		var $this = $(this);
			
		$this.lazyload({
			threshold: Math.max(200, Math.min(400, parseInt(window.innerHeight / 2))),
			effect: 'fadeIn'
		});
	});
}

$(document).ready(function() {
	App.showStoreButtons();
	App.lazyload();
});